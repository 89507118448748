<template>
<!-- 积分奖励列表 -->
   <a-card :bordered='false'>
      <a-row class="query-row" type='flex' :gutter="12">
         <a-col style="width:210px">
           <span>受益人：</span>
           <a-input v-model="queryData.userPhone" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:210px">
           <span>分享人：</span>
           <a-input v-model="queryData.sharePhone" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:210px">
           <span>下单人：</span>
           <a-input v-model="queryData.buyerPhone" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:230px">
           <span>主订单号：</span>
           <a-input v-model="queryData.orderBn" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:230px">
           <span>子订单号：</span>
           <a-input v-model="queryData.orderSn" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:220px">
           <span>奖励发放状态：</span>
           <a-select v-model="queryData.status" allowClear placeholder='全部'>
               <a-select-option v-for="(val,key) in rewardStatus" :key='key' :value='key'>{{val}}</a-select-option>
            </a-select>
        </a-col>
        <a-col style="width:240px">
           <span>积分类型：</span>
           <a-select v-model="queryData.jifenType" allowClear placeholder='全部'>
               <a-select-option :value="1">销售积分</a-select-option>
               <a-select-option :value="2">奖励积分</a-select-option>
               <a-select-option :value="21">系统发放(推广积分)</a-select-option>
               <a-select-option :value="22">系统发放(激活积分)</a-select-option>
            </a-select>
        </a-col>
        <a-col style="width:270px">
           <span>奖励类型：</span>
           <a-select v-model="queryData.type" allowClear placeholder='全部'>
               <a-select-option v-for="(val,key) in rewardType" :key="key" :value="key">{{val}}</a-select-option>
            </a-select>
        </a-col>
         <a-col style="width:410px">
            <span>奖励发放时间：</span>
            <a-range-picker
               v-model="rewardTime" 
               :show-time="showTime" 
               @change='changeRewardTime'  
                />
         </a-col>
         <a-col style="width:410px">
            <span>订单支付时间：</span>
            <a-range-picker 
            :show-time="showTime" 
            @change='changePayTime'  
            v-model="payTime"
            :ranges="pickerRange"
            />
         </a-col>
         <a-col style="width:200px">
           <span>购买方式：</span>
           <a-select v-model="queryData.isSelf" allowClear placeholder='请选择'>
               <a-select-option v-for="(val,key) in isSelf" :key="key" :value="key">{{val}}</a-select-option>
            </a-select>
        </a-col>
         <a-col>
            <a-button @click="() => {this.pageNum = 1;this.loadTableData();this.jifenStatistics()}" style="margin-right:12px" type='primary'>查询</a-button>
            <a-button type='primary' @click="clearSearchParams()" style="margin-right:12px">重置</a-button>
            <a-button type='primary' @click="$refs.exportModal.show()" v-auth='"jifenList:export"'>导出</a-button>
         </a-col>
      </a-row>
      <a-spin :spinning="loading">
         <a-card :bordered='false' hoverable class="query-total" v-if="statistics !== null">
            <a-row>当前查询积分合计：{{statistics.total}}</a-row>
            <a-row>
               <span style="margin-right:24px">已发放：{{statistics.sentTotal}}</span>
               <span>待发放：{{statistics.waitSendTotal}}</span>
            </a-row>
         </a-card>
         <a-card v-else>暂无数据</a-card>
      </a-spin>
      <a-table :columns="columns" :dataSource="tableData" :pagination="false" :rowKey="record => record.id "
             :loading="loading"
             :scroll="{x: true}"
             size="default">
      </a-table>
      <a-pagination
         style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
         size="small"
         v-model="pageNum"
         :total="total"
         :showTotal="total => `共 ${total} 条`"
         showSizeChanger
         showLessItems
         showQuickJumper
         :pageSize.sync="pageSize"
         @change="loadTableData"
         @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
      />

      <!--导出modal-->
      <ExportModal
         ref="exportModal"
         :exportUrl="jifenExportUrl"
         :params="{...queryData,pageNum,pageSize}"
         :total="total"
      >
      </ExportModal>
   </a-card>
</template>

<script>
import moment from 'moment'
import api from '@/api'
import ExportModal from '@/components/ExportModal'
import { rewardStatus, buyMethods} from '@/config'
export default {
   name:'RewardScoreList',
   components: {
      ExportModal
   },
   data () {
      return {
         jifenExportUrl:api.marketManage.jifenExportUrl,
         rewardStatus:rewardStatus,
         showTime:{
            format: 'HH:mm:ss',
            defaultValue:[moment('00:00:00', 'HH:mm:ss'),moment('23:59:59', 'HH:mm:ss')]
         },
         columns: [
            {
               title: '序号',
               align: 'center',
               ellipsis:true,
               customRender: (_, __, index) => {
                  return index + 1
               }
            },
            {
               title: '受益人',
               dataIndex:'mobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '积分类型',
               dataIndex:'jifenType',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  /* 
                     1-推广积分 2-激活积分 3奖励积分
                     积分类型：包括
                        1）销售积分（PS：推广积分和激活积分）
                        2）奖励积分
                  */
                 let textMap = {
                    '1': '销售积分',
                    '2': '销售积分',
                    '3': '奖励积分',
                    '21': '系统发放(推广积分)',
                    '22': '系统发放(激活积分)'
                 }
                 return textMap[text]
               }
            },
            {
               title: '奖励类型',
               dataIndex:'rewardType',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  return this.rewardType[text]
               }
            },
            {
               title: '积分数值',
               dataIndex:'rewardAmount',
               align: 'center',
               ellipsis:true,
            },
            {
               title: '分享人',
               dataIndex:'shareMobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '下单人',
               align: 'center',
               ellipsis:true,
               dataIndex:'buyerMobile'
            },
            {
               title: '主订单号',
               align: 'center',
               ellipsis:true,
               dataIndex:'orderBn'
            },
            {
               title: '子订单号',
               align: 'center',
               ellipsis:true,
               dataIndex:'orderSn'
            },
            {
               title: '购买方式',
               dataIndex: 'isSelf',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  return this.isSelf[text]
               }
            },
            {
               title: '奖励发放状态',
               align: 'center',
               ellipsis:true,
               dataIndex:'status',
               customRender: text => {
                  return this.rewardStatus[text]
               }
            },
            {
               title: '奖励发放时间',
               align: 'center',
               ellipsis:true,
               dataIndex:'time',
               customRender: text => {
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '-'
               }
            },
            {
               title: '订单支付时间',
               align: 'center',
               ellipsis:true,
               dataIndex:'payTime',
               customRender: text => {
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '-'
               }
            },
         ],
         rewardType: {
            '1': '奖励积分销售奖',
            '2': '奖励积分小汇俱乐部补贴',
            '3': '奖励积分小汇服务商补贴',
            '4': '奖励积分小汇俱乐部培育奖',
            '5': '奖励积分小汇服务商培育奖',
            '6': '出单奖励',
            '25': '超级爆品排行榜',
            '101': '激活奖励(销售奖)',
            '102': '激活奖励(小汇俱乐部补贴)',
            '103': '激活奖励(小汇服务商补贴)',
            '104': '激活奖励(小汇俱乐部培育)',
            '105': '激活奖励(小汇服务商培育)',
            '99': '用户退单',
            '26': '企业购推荐奖'
         },
         tableData: [],
         loading: false,
         pageNum: 1,
         total: 0,
         pageSize: 10,
         queryData: {
            userPhone: undefined,
            sharePhone: undefined,
            buyerPhone: undefined,
            orderBn: undefined,
            orderSn: undefined,
            status: undefined,
            type: undefined,
            jifenType: undefined,
            beginTime: undefined,
            endTime: undefined,
            beginPayTime: undefined,
            endPayTime: undefined,
            isSelf: undefined
         },
         rewardTime: [],
         payTime: [],
         statistics: {},
         pickerRange:{},
         //购买方式
         isSelf: buyMethods,
      }
   },
   mounted () {
      this.initSearchParams()
      this.loadTableData()
      this.jifenStatistics()
   },
   methods: {
      // 初始化参数，第一次进入页面只查当天时间的
      initSearchParams(){
         const day_start = moment().startOf('day')
         const day_end =moment().endOf('day')
         this.pickerRange = { 
            '今日': [moment().startOf('day'), moment().endOf('day')], 
            '本周': [moment().startOf('week'), moment().endOf('week')], 
            '本月': [moment().startOf('month'), moment().endOf('month')]
         }
         // this.payTime = [day_start,day_end]
         // this.queryData.beginPayTime = day_start.format('X');
         // this.queryData.endPayTime = day_end.format('X');
      },
      loadTableData () {
         let p = {
            ...this.queryData,
            pageNum:this.pageNum,
            pageSize:this.pageSize
         }
         this.loading = true
         api.marketManage.jifenList(p).then(res => {
            if (res.code === '00000') {
               this.tableData = res.data.dataList
               this.total = res.data.total
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => this.loading = false)
      },
      changeRewardTime (time) {
         if (time[0] && time[1]) {
            this.queryData.beginTime = new Date(time[0]).getTime() / 1000
            this.queryData.endTime = new Date(time[1]).getTime() / 1000
         } else {
            this.queryData.beginTime = undefined
            this.queryData.endTime = undefined
         }
      },
      changePayTime (time) {
         if (time[0] && time[1]) {
            this.queryData.beginPayTime = new Date(time[0]).getTime() / 1000
            this.queryData.endPayTime = new Date(time[1]).getTime() / 1000
         } else {
            this.queryData.beginPayTime = undefined
            this.queryData.endPayTime = undefined
         }
      },
      jifenStatistics () {
         let p = {
            ...this.queryData,
            pageNum:this.pageNum,
            pageSize:this.pageSize
         }
         api.marketManage.jifenStatistics(p).then(res => {
            if (res.code === '00000') {
               if(res.data.statistics){
                  this.statistics = res.data.statistics
               }else{
                  this.statistics = null
               }
            }
         })
      },
      clearSearchParams(){
         this.queryData = {
            userPhone: undefined,
            sharePhone: undefined,
            buyerPhone: undefined,
            orderBn: undefined,
            orderSn: undefined,
            status: undefined,
            type: undefined,
            jifenType: undefined,
            beginTime: undefined,
            endTime: undefined,
            beginPayTime: undefined,
            endPayTime: undefined,
            isSelf: undefined
         }
         this.rewardTime = []
         this.payTime = []
      },
   }
}
</script>

<style lang="less" scoped>
   /deep/ .query-total > .ant-card-body {
      cursor: auto;
      padding: 6px;
      & > div {
         line-height: 24px;
      }
   }
</style>